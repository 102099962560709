<template>
  <standard-page v-if="registry" :title="registry.name">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
      <span v-if="registry">
        <router-link :to="{ name: 'processdefinitions'}">Registry: {{registry.name | truncate(10, '.....')}}</router-link>
      </span>
      <span>New Process</span>
    </template>
    <template v-slot:content>
      <div class="az-content-label mg-b-5">New Process</div>
      <div class="mg-b-20" v-html="$DEFINITIONS.provenance.newProcessDefinition"></div>
        <form @submit.prevent="handleSubmit">
            <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
                <div class="row row-xs">
              <div class="col-md-5">
                  <input :class="{'is-invalid': submitted && errors.has('definition_name')}"
                         class="form-control"
                         id="definition_name"
                         name="definition_name"
                         data-vv-as="Definition Name"
                         placeholder="Enter Definition name"
                         type="text"
                         v-model="processDefinition.name"
                         v-validate="'required'">
                  <div class="invalid-feedback" v-if="submitted && errors.has('definition_name')">{{ errors.first('definition_name') }}</div>
              </div>
              <div class="col-md-5">
                  <input :class="{'is-invalid': submitted && errors.has('definition_nickname')}"
                         class="form-control"
                         id="definition_nickname"
                         name="definition_nickname"
                         data-vv-as="Definition Nickname"
                         placeholder="Enter Definition Nickname"
                         type="text"
                         v-model="processDefinition.nickname"
                         v-validate="{ required: true, max: 8, regex: /^[a-zA-Z0-9]*$/ }">
                  <span class="mg-l-5 tx-11 text-muted"><i>This nickname will be used in Process name</i></span>
                  <div class="invalid-feedback" v-if="submitted && errors.has('definition_nickname')">{{ errors.first('definition_nickname') }}</div>
              </div>
              <div class="col-md mg-t-10 mg-md-t-0">
                  <button class="btn rounded btn-az-primary btn-block" type="submit">Submit</button>
              </div>
          </div>
            </div>
        </form>
    </template>
  </standard-page>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "NewProcessDefinition",
  props: ['registryid'],
  data() {
    return {
      submitted: false,
      registry: null,
      processDefinition: {
        name: null,
        nickname: null
      }
    };
  },
  mounted() {
    this.getRegistry();
  },
  methods: {
      handleSubmit(e) {
          this.submitted = true;
          this.$validator.validate()
                  .then(valid => {
                      if (valid) {
                          this.createProcessDefinition();
                      }
                  });
      },
    async getRegistry() {
      try {
        let { data } = await this.$provenance.get(`provenance/registries/${this.registryid}`);
        this.registry = data;
      } catch (e) {
        this.$toastr.e("Error retrieving Registry", "Error");
      } finally {

      }
    },
    async createProcessDefinition() {
        try {
          EventBus.$emit('openLoader');
          let result = await this.$provenance.post(`provenance/registries/${this.registryid}/definitions`, this.processDefinition);
          await this.$router.push({ name: 'process-definition-steps', params: { registryid: this.registryid, processdefinitionid: result.data.id } });
        } catch (e) {
          this.$toastr.e("Process Definition creation failed", 'Error');
          await this.$router.push({ name: 'processdefinitions' });
        } finally {
          EventBus.$emit('closeLoader');
        }
    },
  }
}
</script>

<style scoped>
</style>